* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  background-color: #f0f0f0;
}

.App {
  text-align: center;
}

form {
  background-color: #44c767;
  padding: 20px;
  padding-top: 60px;
  margin: 20px auto;
  width: 90%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

input[type="text"],
select {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 2px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
  font-size: 16px;
  letter-spacing: 0.5px;
}

input[type="text"]:focus,
select:focus {
  outline: none;
  border-color: #44c767;
}

label {
  display: block;
  text-align: left;
  margin: 15px 0;
  font-size: 18px;
  color: #333;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  margin-right: 10px;
}

h4 {
  text-align: left;
  color: #333;
  margin-top: 20px;
  font-size: 20px;
}

section {
  border-bottom: 1px solid #ddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.total-score {
  margin-top: 20px;
  font-size: 24px;
  color: #555;
}

.submit-button {
  background-color: #44c767;
  color: white;
  border: none;
  padding: 14px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #3d9e61;
}

@media screen and (max-width: 768px) {
  form {
    padding: 10px;
  }

  h4 {
    font-size: 18px;
  }

  .submit-button {
    font-size: 16px;
  }
}
