.thank-you-screen {
    background-color: #44c767;
    color: white;
    padding: 40px;
    margin-top: 100px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .thank-you-screen h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .thank-you-screen p {
    font-size: 18px;
    margin-bottom: 20px;
  }
  